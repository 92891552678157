import api from '@/utils/api'

export default {
    getFilteredCategories(search, page, size) {
        let query = '?range=' + encodeURIComponent(`[${page - 1},${size}]`) + '&filter=' + encodeURIComponent(isNaN(search) ? `{"title":"%${search}%"}` : `{"code":"${search}"}`) + `&sort=${encodeURIComponent('[id, DESC]')}`
        return api.get(`categories` + query)
    },

    getCategories(page, size) {
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`categories` + (page ? range : ''))
    },

    getCategory(id) {
        return api.get(`categories/${id}`)
    },

    createCategory(data) {
        return api.post('categories', data)
    },

    updateCategory(id, data) {
        return api.put(`categories/${id}`, data)
    },

    getCategoriesByLevel(level) {
        return api.get(`categories/of-level/${level}`)
    },

    getAllCategories() {
        return api.get(`categories/all`)
    },

    deleteCategory(id) {
        return api.delete(`categories/${id}`)
    }
}
