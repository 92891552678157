<template>
    <v-container>
        <v-card class="mb-10">
            <v-card-title class="card-title">Φόρμα Κατηγορίας</v-card-title>
        </v-card>
        <v-form :readonly="readOnly">
            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        ref="categorySelection"
                        v-model="selectedCategoriesByLevel[level]"
                        :items="getFilteredCategories()"
                        :item-text="item => `${item.title}(${item.code})`"
                        placeholder="Επέλεξε/Δημιούργησε Κατηγορία"
                        :label="getCategoryPath()"
                        hide-details
                        return-object
                        :menu-props="{closeOnContentClick:true}"
                        @change="setLevel"
                        @keydown="setSearch"
                    >
                        <template v-slot:append-outer>
                            <v-btn fab x-small dark color="black" class="ml-2" @click="showCreateCategory">
                                <v-icon>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:prepend-inner>
                            <v-btn v-if="level >= 1 && Object.keys(selectedCategoriesByLevel[1]).length > 0" icon small dark color="black" @click="clearPath">
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-if="showCreate || this.$route.params.mode === 'edit'"
                        v-model="category.title"
                        label="Τίτλος"
                        type="text"
                        clearable
                    />
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-if="showCreate || this.$route.params.mode === 'edit'"
                        v-model="category.code"
                        label="Κωδικός"
                        type="Number"
                        :clearable="clearable"
                        @change="category.code = parseInt(category.code)"
                    />
                </v-col>
            </v-row>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="mx-2 white--text" :disabled="shouldDisableSubmit()" :loading="loading" large color="black" @click="onSubmit">
                Καταχώριση
            </v-btn>
            <v-btn class="mx-2 white--text" large color="red" @click="$router.go(-1)">
                Ακύρωση
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import categoryService from '@/services/category'
    import Alert from '@/components/Alert'

    export default {
        name: 'CategoryForm',
        components: { Alert },
        data: () => {
            return {
                showCreate: false,
                categories: [],
                showAlert: false,
                message: null,
                type: null,
                loading: false,
                mode: null,
                level: 1,
                category: {},
                readOnly: false,
                clearable: true,
                startDate: null,
                startTime: null,
                dateRange: null,
                endDate: null,
                endTime: null,
                selectedCategoriesByLevel: { 1: {} },
                rules: [
                    v => !!v || 'Field is required'
                ],
                levelRules: [
                    v => !!v || 'This field is required',
                    v => (v && v >= 0) || 'Level should be above 0'
                ],
                search: null
            }
        },
        created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.categoryId = this.$route.params.categoryId
            if (this.$route.params.categoryId) {
                categoryService.getCategory(this.$route.params.categoryId)
                    .then(resp => {
                        this.category = resp.data
                        this.level = this.category.level
                        this.initSelectedCategoriesByLevel()
                    })
            }
            this.getCategories()
        },
        methods: {
            showCreateCategory() {
                this.$refs.categorySelection.isFocused = false
                this.showCreate = true
                this.category.title = this.search
            },
            isCharacterALetter(char) {
                return char.toLowerCase() !== char.toUpperCase()
            },
            setSearch(value) {
                if (value) {
                    if (value.key.length === 1) {
                        this.search = value.srcElement._value + value.key
                    } else if (value.code === 'Backspace') {
                        this.search = value.srcElement._value.slice(0, value.srcElement.selectionStart - 1) + value.srcElement._value.slice(value.srcElement.selectionEnd)
                    } else if (value.code === 'Delete') {
                        this.search = value.srcElement._value.slice(0, value.srcElement.selectionStart) + value.srcElement._value.slice(value.srcElement.selectionEnd + 1)
                    }
                }
            },
            getFilteredCategories() {
                return this.categories.filter((element) => {
                    if (element.level === this.level) {
                        if (element.parent === null) {
                            return element
                        } else {
                            if (element.parent.id === this.selectedCategoriesByLevel[this.level - 1].id) {
                                return element
                            }
                        }
                    }
                })
            },
            initSelectedCategoriesByLevel() {
                let temp = this.category
                for (let index = this.category.level; index > 0; index--) {
                    this.selectedCategoriesByLevel[index] = temp
                    temp = temp.parent
                }
            },
            clearPath() {
                this.level = 1
                this.selectedCategoriesByLevel = { 1: {} }
                this.category = {}
                this.showCreate = false
                this.setSearch(null)
            },
            getCategoryPath() {
                let path = ''
                for (let index = this.level - 1; index > 0; index--) {
                    path += `/${this.selectedCategoriesByLevel[index].title}(${this.selectedCategoriesByLevel[index].code})`
                }
                return path
            },
            setLevel() {
                if (this.selectedCategoriesByLevel[this.level]) {
                    this.category.parent = this.selectedCategoriesByLevel[this.level]
                    this.showCreate = false
                    this.level += 1
                }
            },
            getCategories() {
                categoryService.getAllCategories().then(resp => {
                    this.categories = resp.data
                })
            },
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            shouldDisableSubmit() {
                return false
            },
            createCategory() {
                this.loading = true
                this.category.level = this.level
                categoryService.createCategory(this.category).then(categoryResponse => {
                    if (categoryResponse.id) {
                        this.categoryId = categoryResponse.id
                    }
                    this.loading = false
                    this.$router.push(`/categories?page=${this.$route.query.page}&size=${this.$route.query.size}`)
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            editCategory() {
                this.loading = true
                categoryService.updateCategory(this.category.id, this.category).then((response) => {
                    this.loading = false
                    this.$router.push(`/categories?page=${this.$route.query.page}&size=${this.$route.query.size}`)
                }).catch((error) => {
                    this.setAlert(error.response.data.message, 'error')
                    this.loading = false
                })
            },
            async onSubmit() {
                if (this.mode === 'edit') {
                    this.editCategory()
                } else if (this.mode === 'create') {
                    this.createCategory()
                }
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
